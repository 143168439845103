var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CopyDrawer" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            "is-mask": false,
            "z-index": 100,
            title: "复制薪资组",
            size: "normal",
            visible: _vm.visible,
          },
          on: {
            close: function ($event) {
              _vm.visible = false
            },
            onSubmit: _vm.onSubmit,
          },
        },
        [
          _c("Form", {
            ref: "form",
            attrs: {
              form: _vm.form,
              "wrapper-col": { span: 20 },
              "label-col": { span: 4 },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }